import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import { Link, graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "grabo"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/grabo.png"}) { ...eyecatchImg },
    benchmark_dq: file(relativePath: { eq: "benchmark_dq.PNG"}) { ...normalImg },
    ai_image: file(relativePath: { eq: "gpu/ai_image.png"}) { ...normalImg },
    bitcoin: file(relativePath: { eq: "gpu/bitcoin.png"}) { ...normalImg },
    cgrabohikaku: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    cgamingpc: file(relativePath: { eq: "cover/pcselection/gamingpc.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`グラフィックボード(グラボ)は必要か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="グラフィックボードは必要？" mdxType="Image" />
    <p>{`3Dゲームをプレイしたいと思っているならば基本的に必須。普通に快適に遊びたい場合は普及価格帯のグラフィックボードを備えておけばよい。その他、動画像の編集でも必須というわけではないが、あると処理速度が上がる場合もある。`}</p>
    <p>{`また３画面以上のマルチディスプレイを構築したい、理系学生でグラフィックボードを使った演算処理を経験してみたい、仮想通貨のマイニングが行いたい、生成AI画像を作成したいといった需要も存在する。`}</p>
    <p>{`いずれも当てはまらないな、と思う人は不要であるが、後々必要かもしれないと少しでも思った人はグラフィックボードとは何かという基本から説明していくので理解を深めてほしい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "グラフィックボードとは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E3%81%A8%E3%81%AF%EF%BC%9F",
        "aria-label": "グラフィックボードとは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グラフィックボードとは？`}</h2>
    <p><strong parentName="p"><em parentName="strong">{`パソコンの描画に関する演算処理を専門に引き受けるパーツ`}</em></strong>{`がグラフィックボードである。単に`}<em parentName="p">{`グラボ`}</em>{`と呼ばれるときや、`}<em parentName="p">{`ビデオボード`}</em>{`、`}<em parentName="p">{`グラフィックアクセラレータ`}</em>{`なども呼ばれる。`}</p>
    <p>{`グラフィックボードの中で計算を担当するコアの部分は`}<em parentName="p">{`GPU`}</em>{`と呼ばれる。`}<em parentName="p">{`Graphic Processing Unit`}</em>{`(グラフィック・プロセッシング・ユニット)の略で、描画演算処理を行うパーツという意味である。`}</p>
    <p>{`CPUが演算処理を行えば良いのではと思うかもしれないが、CPUは単純な演算処理を大量に実行することを苦手としている。`}</p>
    <p>{`たとえば、3Dゲームを考えてみよう。プレイヤーの操作とともに画面の様子をリアルタイムに変えていく必要がある`}</p>
    <p>{`Full HDの画質の場合、横に1920ドット、縦に1080ドット、合計約200万の画素（ピクセル)が存在する。このそれぞれに対してどの色を表示するかを短時間で決めなければならず、大量の演算が必要となる。`}</p>
    <p>{`ところがCPUには演算を担当するコアが数個しかなく、それぞれのコアが数十万におよぶ画素の色を決めなければならず、時間が掛かってしまう。`}</p>
    <p>{`一方でGPUはコア数が多いもので数千を軽く超える。`}<strong parentName="p"><em parentName="strong">{`一つ一つのコアには複雑な演算をこなす能力は無いが、それぞれが演算を平行して行うため(並列処理という)、CPUと比べ高速に処理を実行できる`}</em></strong>{`。`}</p>
    <p>{`このため比較的単純な計算を大量に行う必要がある描画処理は、GPUで行われる。他にも大量の行列演算を行うAI学習でもGPUはメインで使用される。`}</p>
    <h2 {...{
      "id": "グラフィックボード無しのパソコンでも画面出力は可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E7%84%A1%E3%81%97%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A7%E3%82%82%E7%94%BB%E9%9D%A2%E5%87%BA%E5%8A%9B%E3%81%AF%E5%8F%AF%E8%83%BD",
        "aria-label": "グラフィックボード無しのパソコンでも画面出力は可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グラフィックボード無しのパソコンでも画面出力は可能`}</h2>
    <p>{`では、グラフィックボードを搭載していないパソコンでは真っ暗な画面になるのか？と言われるとそうではない。正解は、グラフィックボードは搭載されていないけれど、`}<strong parentName="p"><em parentName="strong">{`GPUはパソコン内のどこかに搭載されているため映像出力ができる`}</em></strong>{`である。`}</p>
    <p>{`インテルのCPUにはGPUが内蔵されており、最小限の描画処理は行うことができる。これらは`}<em parentName="p">{`Intel UHD Graphics`}</em>{`や、`}<em parentName="p">{`Intel Arc Graphics`}</em>{`などの名称で呼ばれる。`}</p>
    <p>{`またCPUやメモリなどを取り付けるパソコンの基盤部品をマザーボードと呼ぶが、このマザーボード内にGPUが搭載されることもある。こちらは`}<em parentName="p">{`オンボードグラフィック`}</em>{`と呼ばれる。`}</p>
    <p>{`いずれも本格的なグラフィックボードが搭載されたパソコンの描画能力には遠く及ばないものの、動画閲覧やライトな3Dゲームには十分な性能を発揮する。`}</p>
    <h2 {...{
      "id": "3Dゲームを快適に楽しむなら必須",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3D%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%92%E5%BF%AB%E9%81%A9%E3%81%AB%E6%A5%BD%E3%81%97%E3%82%80%E3%81%AA%E3%82%89%E5%BF%85%E9%A0%88",
        "aria-label": "3Dゲームを快適に楽しむなら必須 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3Dゲームを快適に楽しむなら必須`}</h2>
    <p>{`さて、これからグラフィックボードが必要となるケースを考えていく。`}</p>
    <p>{`まずは3Dゲームを快適に楽しみたい場合が挙げられる。しかしCPU内臓のGPUやオンボードグラフィックでどのくらい遊べるのかをその前に知っておく必要があるだろう。`}</p>
    <p>{`わざわざグラフィックボードを搭載しなくとも十分かもしれないからである。`}</p>
    <h3 {...{
      "id": "グラボ無しでも可能なゲームはそれなりにある",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B0%E3%83%A9%E3%83%9C%E7%84%A1%E3%81%97%E3%81%A7%E3%82%82%E5%8F%AF%E8%83%BD%E3%81%AA%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AF%E3%81%9D%E3%82%8C%E3%81%AA%E3%82%8A%E3%81%AB%E3%81%82%E3%82%8B",
        "aria-label": "グラボ無しでも可能なゲームはそれなりにある permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`グラボ無しでも可能なゲームはそれなりにある`}</h3>
    <p>{`ゲームを提供するメーカーの立場に立つと、収益機会を増やすためにより多くの人にゲームを遊んでもらいたい。このため`}<strong parentName="p"><em parentName="strong">{`グラフィックボード無しでもグラフィックス設定を調整すること遊べるゲームは多い`}</em></strong>{`。`}</p>
    <p>{`ドラゴンクエストXはその好例であり、下記はIntel HD Graphics 4400という古いデスクトップパソコンでベンチマークテストを行なった例である。`}</p>
    <Image {...props} name="benchmark_dq" caption="ドラゴンクエストXのベンチマーク結果" mdxType="Image" />
    <p>{`ベンチマークとは、パソコンの性能を測るテストの事である。各ゲーム制作会社は、パソコンの使用者がどの程度ゲームを快適に遊べるかを測るテストアプリケーションを配布している。`}</p>
    <p>{`このドラクエのベンチマークテストの場合、グラフィックス設定を"低品質"、"標準品質"、"高品質"の3種類から、解像度を640x480、1280x720、1920x1080の3種類から選べる。`}</p>
    <p>{`テストは標準品質の1280x720グラフィックボード無しで普通という結果であった。
`}<strong parentName="p"><em parentName="strong">{`グラフィックボード無しのデスクトップパソコンでは、軽め3Dゲームが何とか遊べる程度`}</em></strong>{`ということがわかる。`}</p>
    <p>{`まあ`}<strong parentName="p"><em parentName="strong">{`Intel Arcなど最新の内蔵グラフィックだと、調整により大抵のゲームはプレイできる`}</em></strong>{`だろう。`}</p>
    <h3 {...{
      "id": "最低限のグラフィックボード搭載でFFやApex-Legendsが可能に",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%80%E4%BD%8E%E9%99%90%E3%81%AE%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E6%90%AD%E8%BC%89%E3%81%A7FF%E3%82%84Apex-Legends%E3%81%8C%E5%8F%AF%E8%83%BD%E3%81%AB",
        "aria-label": "最低限のグラフィックボード搭載でFFやApex Legendsが可能に permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`最低限のグラフィックボード搭載でFFやApex Legendsが可能に`}</h3>
    <p>{`ドラクエXをより高画質で楽しみたい場合や、より精細な映像が描かれる`}<a parentName="p" {...{
        "href": "https://jp.finalfantasyxiv.com/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ファイナルファンタジーXIV`}</a>{`や`}<a parentName="p" {...{
        "href": "https://www.ea.com/ja-jp/games/apex-legends",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Apex Legends`}</a>{`といったゲームを楽しみたい場合はグラフィックボード無しでは荷が重い。`}</p>
    <p>{`動かせたとしても、ぼやけたカクカクな映像となってしまいゲームにならないであろう。`}</p>
    <p>{`標準的なグラフィックボードとは`}<a parentName="p" {...{
        "href": "https://amzn.to/3svXgMl",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`GTX1650`}</a>{`程度のもので、この2万円程度の価格帯のものを搭載しておけば、ほぼどのゲームでもFullHD(1920x1080)の解像度でそれなりに遊ぶことができる。`}</p>
    <h3 {...{
      "id": "高性能のグラフィックボード搭載で4K、144FPSやVRが可能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%AB%98%E6%80%A7%E8%83%BD%E3%81%AE%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E6%90%AD%E8%BC%89%E3%81%A74K%E3%80%81144FPS%E3%82%84VR%E3%81%8C%E5%8F%AF%E8%83%BD",
        "aria-label": "高性能のグラフィックボード搭載で4K、144FPSやVRが可能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`高性能のグラフィックボード搭載で4K、144FPSやVRが可能`}</h3>
    <p>{`FullHDの4倍高精細な4K出力(3840x2160の解像度)、より滑らかな動きを実現する144FPS、VRなどを楽しむためにはより高価なグラフィックボードを搭載する必要がある。`}</p>
    <p>{`VRは平面画像ではなく、`}<strong parentName="p"><em parentName="strong">{`自身を取り巻く360度の世界をリアルタイムで描画するため膨大な演算が必要`}</em></strong>{`とされるため、高価なグラフィックボードが必須となっている。`}</p>
    <p>{`このクラスになるとPS5と同程度かそれ以上の性能となり、コストパフォーマンス重視だと`}<a parentName="p" {...{
        "href": "https://amzn.to/4dXrjE8",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`RTX4060`}</a>{`、最強クラスのものだと`}<a parentName="p" {...{
        "href": "https://amzn.to/3woHyH3",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`RTX4070Ti`}</a>{`などが選択肢となる。`}</p>
    <p>{`注意点としては、4Kは4Kモニター、144FPSもそれに対応できる`}<a parentName="p" {...{
        "href": "https://amzn.to/37RdkAH",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ゲーミングモニター`}</a>{`、VRはVRヘッドセットとそれぞれ対応した機器が別途必要となることである。`}</p>
    <p>{`GPUは種類も多く、比較するのも大変なので、最終的には下記のGPU比較表を確認して、ある位程度目安を付ければ良いだろう。`}</p>
    <Card {...props} title="グラボ(GPU)性能比較表" description="グラフィックボードの比較を簡単に。ゲームに必要な性能をFullHD、QHD、4Kの参考フレームレート、3DMark、コスパ、TDP等で比較します。" image="cgrabohikaku" path="/gpu/" mdxType="Card" />
    <h3 {...{
      "id": "ゲームをする上でのグラボ以外の注意点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%92%E3%81%99%E3%82%8B%E4%B8%8A%E3%81%A7%E3%81%AE%E3%82%B0%E3%83%A9%E3%83%9C%E4%BB%A5%E5%A4%96%E3%81%AE%E6%B3%A8%E6%84%8F%E7%82%B9",
        "aria-label": "ゲームをする上でのグラボ以外の注意点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームをする上でのグラボ以外の注意点`}</h3>
    <p>{`グラフィックボードが高度な3Dゲームにおいて重要であること、より高度なグラフィックを体験するには専用のモニターが必要なことは理解してもらえたと思うが、他にキーボードとマウスも重要である。`}</p>
    <p>{`普通のキーボードは同時ボタン押しを３つ程度しか判定することができず、ボタンの同時押しを求められるゲームではまともに遊ぶことができない。よって`}<strong parentName="p"><em parentName="strong">{`Nキーロールオーバーという同時押しに対応したキーボードが求められる`}</em></strong>{`。`}</p>
    <p>{`またシューティングゲームで敵を狙うためには細かいマウスの動作が必要であるが、`}<strong parentName="p"><em parentName="strong">{`移動感度を切り替えられるゲーミングマウスが必要となってくる`}</em></strong>{`。`}</p>
    <p>
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fbrand%2Fnextgear%2F" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" alt="" />NEXTGEAR</a>はこれら一式を揃えたゲーミングパソコンを低価格で販売しているため、初心者にはおすすめである。しっかりとゲームに対応したパソコンを自分で選びたい場合は下記の記事も参考にしてほしい。
    </p>
    <Card {...props} title="【初心者向け】ゲーミングPCの選び方" description="FullHDで60FPSをボトムとして144FPS、4K、レイトレーシングを実現する快適なパソコンスペックを追求" image="cgamingpc" path="/gamingpc/" mdxType="Card" />
    <h2 {...{
      "id": "動画・画像編集ソフトでも有効活用できる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8B%95%E7%94%BB%E3%83%BB%E7%94%BB%E5%83%8F%E7%B7%A8%E9%9B%86%E3%82%BD%E3%83%95%E3%83%88%E3%81%A7%E3%82%82%E6%9C%89%E5%8A%B9%E6%B4%BB%E7%94%A8%E3%81%A7%E3%81%8D%E3%82%8B",
        "aria-label": "動画・画像編集ソフトでも有効活用できる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画・画像編集ソフトでも有効活用できる`}</h2>
    <p>{`大量の演算をこなすことがGPUの得意とするところであるが、動画像編集ソフトでも効果を発揮する。背景ぼかしや光の陰影などの映像効果を付与する時、あるいは動画のエンコードを行う時などには大量の演算が必要となり、グラフィックボードでの処理が高速化の役に立つ。`}</p>
    <p>{`エンコードとはデータを別の形式に変更することであり、例としては、動画ソフトで編集した動画をYoutubeにアップロードできる形式に変換する作業が挙げられる。Full HD、4Kと画質が上がるとその分時間が掛かってしまい、高い解像度ではCPUより高速なGPUの演算が効果を発揮する。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`3D映像を作成しようとした場合はほぼGPUの搭載は必須`}</em></strong>{`であり、GPUが無いとレンダリングと呼ばれる光の計算作業が遅く、途中状態の動画のプレビューを見ては修正するというトライ・アンド・エラーがスムーズに行なえない。`}</p>
    <p>{`ただ、GPUは高ければいいということはなく、こと`}<strong parentName="p"><em parentName="strong">{`3D系の作業ではGPU投資に対する作業効率のカーブは徐々に下がっていく傾向`}</em></strong>{`にあるため、予算にあわせてGPUを組めばよい。`}</p>
    <p>
マウスコンピュータではクリエイター向けに<a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902208" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902208" height="1" width="1" border="0" alt="" />DAIV</a>というブランドを扱っているが、低価格帯のグラフィックボードが搭載されているものが多い。
    </p>
    <p>{`まとめると、無くても問題はないが、`}<strong parentName="p"><em parentName="strong">{`日常的に動画編集を行なう人とコンピュータグラフィックスなど3D系の映像を扱う人はグラフィックボードを搭載した方がよい`}</em></strong>{`だろう。`}</p>
    <h2 {...{
      "id": "その他グラフィックボードを使用するケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E3%82%B0%E3%83%A9%E3%83%95%E3%82%A3%E3%83%83%E3%82%AF%E3%83%9C%E3%83%BC%E3%83%89%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%99%E3%82%8B%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "その他グラフィックボードを使用するケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他グラフィックボードを使用するケース`}</h2>
    <h3 {...{
      "id": "AI画像の生成",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#AI%E7%94%BB%E5%83%8F%E3%81%AE%E7%94%9F%E6%88%90",
        "aria-label": "AI画像の生成 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AI画像の生成`}</h3>
    <Image {...props} name="ai_image" caption="AI画像の生成" mdxType="Image" />
    <p><strong parentName="p"><em parentName="strong">{`クリエイティブ関連のGPUの使い方で楽しい遊びの一つがAI画像の生成`}</em></strong>{`である。上記はStable Diffusionと呼ばれるディープラーニングモデルを使用して画像生成をしている様子である。`}</p>
    <p>{`これにはかなりのGPUパワーが必要であり、RTX 4060などの`}<strong parentName="p"><em parentName="strong">{`ミドルクラスGPUであっても768x1024程度の画像を１枚作るのに10秒程度`}</em></strong>{`はかかる。`}</p>
    <p>{`生成AI画像の世界は自分の希望した画像が表示されるまでガチャのように生成を続けるのが普通の使い方であるので、スペックが高いGPUを使うほど恩恵を受けやすい。`}</p>
    <p>{`GPUを搭載していない場合は、オンライン上で課金して画像を作ることは可能であるが、自前のPCでの生成はほぼ試すことはできないだろう。`}</p>
    <h3 {...{
      "id": "3画面以上のマルチディスプレイ環境の構築",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3%E7%94%BB%E9%9D%A2%E4%BB%A5%E4%B8%8A%E3%81%AE%E3%83%9E%E3%83%AB%E3%83%81%E3%83%87%E3%82%A3%E3%82%B9%E3%83%97%E3%83%AC%E3%82%A4%E7%92%B0%E5%A2%83%E3%81%AE%E6%A7%8B%E7%AF%89",
        "aria-label": "3画面以上のマルチディスプレイ環境の構築 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3画面以上のマルチディスプレイ環境の構築`}</h3>
    <p>{`ディスプレイを3画面以上並べ、プログラミングやトレード（株、為替取引)を効率良く行いたい場合にもグラフィックボードが必要である。`}<strong parentName="p"><em parentName="strong">{`通常のデスクトップパソコンでは2画面までしか出力できない`}</em></strong>{`ためである。`}</p>
    <p>{`グラフィックボード以外を使用するケースとして、`}<a parentName="p" {...{
        "href": "https://amzn.to/37Sq4He",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`USBマルティディスプレイアダプター`}</a>{`を用いるという手段もある。手軽にマルチディスプレイ環境の構築ができるというメリットがあるが、環境によっては動作が不安定になりがちである。`}</p>
    <p>{`安定したマルチディスプレイ環境を構築したい場合はグラフィックボードを搭載すると良い。`}<strong parentName="p"><em parentName="strong">{`ゲームをするので無ければ性能は必要はないので、5000円から10000円程度のグラフィックボードで十分である`}</em></strong>{`。`}</p>
    <h3 {...{
      "id": "大量の演算を要するプログラミング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%A7%E9%87%8F%E3%81%AE%E6%BC%94%E7%AE%97%E3%82%92%E8%A6%81%E3%81%99%E3%82%8B%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0",
        "aria-label": "大量の演算を要するプログラミング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`大量の演算を要するプログラミング`}</h3>
    <p><em parentName="p">{`GPGPU`}</em>{`という言葉を知っているだろうか？General-Purpose computing on Graphics Processing Unitsの略で、General-Purposeは一般的な用途、すなわち本来グラフィックの演算のみに使われていたGPUを、他の用途にも応用して活用することを指す。`}</p>
    <p>{`科学計算、株式分析、気候変動の分析、暗号解読、リアルタイム機械翻訳の音声処理、ディープラーニングの機械学習など、幅広く活用されており、トヨタとGeforceのメーカーであるNVIDIAが提携したことからもわかるように自動運転技術にも使われる。`}</p>
    <p>{`GPUを活用して演算を行うには、GPUプログラミング言語を記述する必要がある。`}</p>
    <p>{`NVIDIAが提供する`}<a parentName="p" {...{
        "href": "https://developer.nvidia.com/cuda-downloads",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`CUDA`}</a>{`や、アップルにより提案された`}<a parentName="p" {...{
        "href": "https://www.khronos.org/opencl/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`OpenCL`}</a>{`などの言語が存在する。CUDAは`}<a parentName="p" {...{
        "href": "https://amzn.to/3stSEXh",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`技術書`}</a>{`も多く出版されており、とっつきやすいかもしれない。`}</p>
    <p>{`というわけで、`}<strong parentName="p"><em parentName="strong">{`GPUを活用してプログラミングが行いたい場合は、CUDAが使えるGeforceのグラフィックボードを搭載しておこう。`}</em></strong></p>
    <h3 {...{
      "id": "仮想通貨のマイニング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BB%AE%E6%83%B3%E9%80%9A%E8%B2%A8%E3%81%AE%E3%83%9E%E3%82%A4%E3%83%8B%E3%83%B3%E3%82%B0",
        "aria-label": "仮想通貨のマイニング permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`仮想通貨のマイニング`}</h3>
    <Image {...props} name="bitcoin" alt="ビットコインのマイニング" mdxType="Image" />
    <p>{`ビットコインなど仮想通貨のマイニングにもグラフィックボードが使われる。電気代の高い日本では利益が出ても大した額にはならないが、趣味としての人気は高い。高価なグラフィックボードを大量に買い、大規模にマイニング作業を行っている人も多い。`}</p>
    <p>{`通貨を得るためには一定の演算を行う必要があり、この行為をマイニングと呼ぶ。マイニングとは日本語で採掘の意味である。自然鉱物と同様に、通貨の総量は決められており、次第に通貨を得る事が難しくなる。`}</p>
    <p>{`通貨の価値とマイニングの難易度を比べながら、どの通貨を掘るかを決めて、実際に掘っていくことが醍醐味である。ゲームの空き時間にでも掘ってみると楽しいのではないかと思う。`}</p>
    <p>{`高価なグラフィックボードだとそれだけ電気代がかかるため、必ずしも割が良いわけではない。かつグラフィックボードにより採掘が得意な通貨が異なるため、どのグラフィックボードが良いかは一概には言えない。`}</p>
    <h2 {...{
      "id": "あとから欲しい人はタワー型のPCを購入しておこう",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%82%E3%81%A8%E3%81%8B%E3%82%89%E6%AC%B2%E3%81%97%E3%81%84%E4%BA%BA%E3%81%AF%E3%82%BF%E3%83%AF%E3%83%BC%E5%9E%8B%E3%81%AEPC%E3%82%92%E8%B3%BC%E5%85%A5%E3%81%97%E3%81%A6%E3%81%8A%E3%81%93%E3%81%86",
        "aria-label": "あとから欲しい人はタワー型のPCを購入しておこう permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`あとから欲しい人はタワー型のPCを購入しておこう`}</h2>
    <p>{`以上がグラフィックボードを活用できるケースである。今すぐには必要はないけれども、今後必要になるかもしれないと思った人もいることだろう。しかし、グラフィックボードはサイズが大きく、ある拡張性の高いパソコンでないと増設することが難しい。`}</p>
    <p>{`よって、`}<strong parentName="p"><em parentName="strong">{`今は欲しくないがいずれ、、と考えている人はタワー型、あるいはミニタワー型と呼ばれる大きめのパソコンを購入`}</em></strong>{`し、グラフィックボードの挿し込みスペースを確保しておこう。`}</p>
    <p>{`またグラフィックボードは電力の消費も大きいため、RTX3060などVRや高画質な3Dを快適に楽しめるクラスのグラフィックボードを搭載する予定ならば`}<strong parentName="p"><em parentName="strong">{`最低450W程度の電源は必要なため購入時に注意しよう`}</em></strong>{`。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      